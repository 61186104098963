import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "ProtocolUser" */ '../views/Help.vue')
  },
  {
    path: '/share/:shareId',
    name: 'sharePage',
    component: () => import(/* webpackChunkName: "ProtocolUser" */ '../views/share/Save.vue')
  },
  {
    path: '/protocol/user',
    name: 'protocol-user',
    component: () => import(/* webpackChunkName: "ProtocolUser" */ '../views/ProtocolUser.vue')
  },
  {
    path: '/protocol/privacy',
    name: 'protocol-privacy',
    component: () => import(/* webpackChunkName: "ProtocolPrivacy" */ '../views/ProtocolPrivacy.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
   return { x: 0, y: 0 }
  }
})

export default router

// export const USER_API_HOST = 'http://localhost:6002'
export const USER_API_HOST = 'https://user.yimiaopan.com'
export const SERVICE_ADDR = 'https://osnd-device.krzb.net'

export const EMPTY_STRING = ''

export const OFFLINE_TASK_STATUS = {
  WAITING_TORRENT: -1,
  CREATED: 1,
  WAITING: 2,
  DOWNLOADING: 3,
  FINISHED: 4,
  FAILED: 5,
  CANCELED: 6,
}

export const DOWNLOAD_STATUS = {
  ERROR: 'error',
  ACTIVE: 'active',
  PAUSED: 'paused',
  COMPLETE: 'complete',
}

export const UPLOAD_STATUS = {
  WAIT: 'wait',
  DONE: 'done',
  PAUSE: 'pause',
  CHECK: 'check',
  ERROR: 'error',
  UPLOADING: 'uploading',
}

export const APP_COMMAND = {
  WILL_QUIT: 'app-will-quit',
  MINIMIZE_WINDOW: 'minimize-window',
  MAXIMIZE_WINDOW: 'maximize-window',
  CLOSE_WINDOW: 'close-window',
  APP_RELAUNCH: 'app-relaunch'
}

export const POWER_COMMAND = {
  START_PREVENT_SLEEP: 'start-prevent-display-sleep',
  STOP_PREVENT_SLEEP: 'stop-prevent-display-sleep'
}

export const UPLOAD_COMMAND = {
  START_UPLOAD: 'start-upload',
  ADD_UPLOAD_ITEM: 'add-upload-item',
  CHECK_UPLOAD_ITEM: 'check-upload-item',
  PAUSE_UPLOAD_ITEM: 'pause-upload-item',
  START_UPLOAD_ITEM: 'start-upload-item',
  DELETE_UPLOAD_ITEM: 'delete-upload-item',
  RESTART_UPLOAD_ITEM: 'restart-upload-item',
  UPLOAD_ITEM_SUCCESS: 'success-upload-item',
  UPLOAD_ITEM_ERROR: 'error-upload-item',
  REFRESH_UPLOAD_LIST: 'refresh-upload-list',
  REFRESH_FILE_LIST: 'refresh-file-list',
  START_UPLOAD_ALL: 'start-upload-all',
  PAUSE_UPLOAD_ALL: 'pause-upload-all',
  DELETE_UPLOAD_ALL: 'delete-upload-all',
  DELETE_UPLOAD_ALL_DONE: 'delete-upload-all-cone',
}

export const DOWNLOAD_COMMAND = {
  ADD_DOWNLOAD_FILES: 'add-download-files',
  START_DOWNLOAD_ITEM: 'start-download-item',
  PAUSE_DOWNLOAD_ITEM: 'pause-download-item',
  DELETE_DOWNLOAD_ITEM: 'delete-download-item',
  START_DOWNLOAD_ALL: 'start-download-all',
  PAUSE_DOWNLOAD_ALL: 'pause-download-all',
  DELETE_DOWNLOAD_ALL: 'delete-download-all',
  DELETE_DOWNLOAD_ALL_DONE: 'delete-download-all-done',
  DOWNLOAD_TASK_CHANGE: 'download-task-change',
  REFRESH_DOWNLOAD_LIST: 'refresh-download-list',
}

export const UPDATE_COMMAND = {
  DONE: 'update-done',
  ERROR: 'update-error',
  CHECK: 'update-check',
  START: 'update-start',
  CHECKING: 'update-checking',
  PROGRESS: 'update-progress',
  DOWNLOADED: 'update-downloaded',
  NEW_VERSION: 'update-new-version',
  ALREADY_NEW_VERSION: 'update-already-new-version',
}

export const CORE_UPDATE_COMMAND = {
  START: 'core-start-update',
  PROGRESS: 'core-update-progress',
  SUCCESS: 'core-update-success',
  ERROR: 'core-update-error',
}

export const ASAR_UPDATE_COMMAND = {
  START: 'asar-start-update',
  PROGRESS: 'asar-update-progress',
  SUCCESS: 'asar-update-success',
  ERROR: 'asar-update-error',
}

export const RENDER_PROCESS_COMMAND = {
  READY: 'ipcrender-ready',               // 可以进行 ipcrender 通信了
  REFRESH_TOKEN: 'refresh-token',         // 刷新用户 uid 和 sessionid 信息
  REFRESH_USERINFO: 'refresh-userinfo',   // 刷新用户信息
  MESSAGE_FROM_CORE: 'message-from-core', // 接受来自内核的消息
  SET_CORE_SESSION: 'set-core-session',   // 内核设置 session 成功
}

export const VIP_GRADE = {
  normal: 0,
  vip: 1,
  super_vip: 2,
  0: {
    vipgrade: 0,
    name: '免费会员',
    icon: 'https://res.oodcd.cn/1second/images/icons/vip_user_0.png',
  },
  1: {
    vipgrade: 1,
    name: '普通会员',
    icon: 'https://res.oodcd.cn/1second/images/icons/vip_user_1.png',
  },
  2: {
    vipgrade: 2,
    name: '高级会员',
    icon: 'https://res.oodcd.cn/1second/images/icons/vip_user_2.png',
  },
}

export const DOWNLOAD_ITEM_TYPE = {
  CLOUD: 'cloud',
  REMOTE: 'remote',
}

export const UPLOAD_ITEM_TYPE = {
  LOCAL: 'local',
  REMOTE: 'remote',
}

export const CORE_EVENT = {
  DEVICE_LOCK: 'server-event-device-lock-change',
  DEVICE_AUTH: 'device-auth',
  FORCE_LOGOUT: 'force-logout',
  TASK_CHANGED: 'task-changed',
  DEVICE_LIST_UPDATED: 'device-list-updated',
  BROKER_CONNECTION_CHANGED: 'broker-connection-changed',
  PEER_CONNECTION_CHANGE: 'peer-connection-state-change'
}

export const PEER_STATUS = {
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  CLOSED: 'closed',
  ERROR: 'error',
}

export const SHARE_LINK_REG = /https?:\/\/www\.yimiaopan\.com\/share\/([a-zA-Z0-9_-]{12,12})/i
const FILE_TYPE_PPT = require('@/assets/images/filetype/ppt.png')
const FILE_TYPE_XLS = require('@/assets/images/filetype/excel.png')
const FILE_TYPE_DOC = require('@/assets/images/filetype/doc.png')
const FILE_TYPE_PDF = require('@/assets/images/filetype/pdf.png')
const FILE_TYPE_ZIP = require('@/assets/images/filetype/zip.png')
const FILE_TYPE_GIF = require('@/assets/images/filetype/gif.png')
const FILE_TYPE_IMG = require('@/assets/images/filetype/image.png')
const FILE_TYPE_VIDEO = require('@/assets/images/filetype/video.png')
const FILE_TYPE_AUDIO = require('@/assets/images/filetype/audio.png')
const FILE_TYPE_OTHER = require('@/assets/images/filetype/other.png')
const FILE_TYPE_FOLDER = require('@/assets/images/filetype/folder.png')
const FILE_TYPE_UNKNOWN = require('@/assets/images/filetype/unknown.png')
const FILE_TYPE_BT = require('@/assets/images/filetype/bt.png')
const FILE_TYPE_EPUB = require('@/assets/images/filetype/epub.png')
const FILE_TYPE_TXT = require('@/assets/images/filetype/txt.png')
const FILE_TYPE_MOBI = require('@/assets/images/filetype/mobi.png')
const FILE_TYPE_APK = require('@/assets/images/filetype/apk.png')
const FILE_TYPE_EXE = require('@/assets/images/filetype/exe.png')
const FILE_TYPE_PSD = require('@/assets/images/filetype/psd.png')
const FILE_TYPE_AI = require('@/assets/images/filetype/ai.png')
const FILE_TYPE_AZW3 = require('@/assets/images/filetype/azw3.png')
const FILE_TYPE_SKETCH = require('@/assets/images/filetype/sketch.png')
const FILE_TYPE_PAGES = require('@/assets/images/filetype/pages.png')
const FILE_TYPE_NUMBERS = require('@/assets/images/filetype/numbers.png')
const FILE_TYPE_KEYNOTE = require('@/assets/images/filetype/keynote.png')

export default {
  FILE_TYPE_PPT,
  FILE_TYPE_TXT,
  FILE_TYPE_XLS,
  FILE_TYPE_DOC,
  FILE_TYPE_PDF,
  FILE_TYPE_ZIP,
  FILE_TYPE_IMG,
  FILE_TYPE_GIF,
  FILE_TYPE_VIDEO,
  FILE_TYPE_AUDIO, 
  FILE_TYPE_OTHER, 
  FILE_TYPE_FOLDER,
  FILE_TYPE_UNKNOWN,
  FILE_TYPE_BT,
  FILE_TYPE_EPUB,
  FILE_TYPE_MOBI,
  FILE_TYPE_APK,
  FILE_TYPE_EXE,
  FILE_TYPE_PSD,
  FILE_TYPE_AI,
  FILE_TYPE_AZW3,
  FILE_TYPE_SKETCH,
  FILE_TYPE_PAGES,
  FILE_TYPE_NUMBERS,
  FILE_TYPE_KEYNOTE,
}
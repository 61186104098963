import Vue from 'vue'
import Vuex from 'vuex'

import pan from './modules/pan'

Vue.use(Vuex)

const state = {
  isCoreInit: false,
}

const getters = {}

const mutations = {
  UPDATE_CORE_STATUS (state, data) {
    state.isCoreInit = data
  },
}

const actions = {
  updateCoreStatus ({ commit }, data) {
    commit('UPDATE_CORE_STATUS', data)
  },
}
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    pan,
    strict: process.env.NODE_ENV !== 'production',
  }
})

<template>
  <div class="home-page">
    <div class="top">
      <div class="header">
        <img src="../assets/logo_with_text.png" class="logo" alt="一秒云联">
        <div class="nav">
          <a href="https://shimo.im/docs/Tc8rrYtYKqv8h3Dy" target="_blank" class="nav-item">帮助中心</a>
        </div>
      </div>
      <div class="intro-wrap">
        <div class="left">
          <h1>一秒云联<br>更便捷的多端实时访问管家</h1>
          <p class="intro-tip">一秒云联是一款支持多端实时访问、速度快、安全便捷的云盘，你可以在一台设备实时访问远端多台设备授权文件，尽情享受本地即云的快感。</p>
          <div class="button-wrap">
            <a class="btn" target="_blank" :href="config.mac">下载 MAC 版</a>
            <a class="btn" target="_blank" :href="config.windows">下载 Window 版本</a>
          </div>
          <p class="tip">最新版本:{{config.version}}，更新于:{{config.updatetime}}</p>
        </div>
        <img src="../assets/header_spot.png" class="right">
      </div>
    </div>
    <div class="summary">
      <h1>本地即云 - 安全便捷</h1>
      <p class="summary-tip">区别于传统的云盘访问限制、传输速度限制、多端设备的不同步限制，一号多端，安全授权，实时访问，给你不一样的体验</p>
    </div>
    <div class="feature-section">
      <div class="feature-wrap">
        <div class="feature-item">
          <div class="feature-intro">
            <h3>本地即云</h3>
            <p class="detail">本地文件无需放到云端、设备授权、远程访问、双重验证、实时预览、安全便捷</p>
          </div>
          <img src="../assets/feature_spot_1.png" class="icon">
        </div>
        <div class="feature-item">
          <img src="../assets/feature_spot_2.png" class="icon">
          <div class="feature-intro">
            <h3>安全和隐私保护</h3>
            <p class="detail">文件授权访问，多端登录管理，本机管理，远端访问，给你安全可靠的文件传输、储存、访问保护</p>
          </div>
        </div>
        <div class="feature-item">
          <div class="feature-intro">
            <h3>智能化文件管理</h3>
            <p class="detail">强大的AI功能，智能区分文件类别，便捷多样的人性化自定义方式，给你一个属于自己的云盘空间</p>
          </div>
          <img src="../assets/feature_spot_3.png" class="icon">
        </div>
        <div class="feature-item">
          <img src="../assets/feature_spot_4.png" class="icon">
          <div class="feature-intro">
            <h3>极速访问</h3>
            <p class="detail">可选择免费线路中转或多线高速通道+CDN专线加速，给你最贴身实时访问的体验感</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="nav">
        <p class="title">联系我们</p>
        <p class="item">QQ群：906869378</p>
        <p  class="item">微信公众号：<span class="em">一秒云联</span></p>
      </div>
      <div class="nav">
        <p class="title">更多</p>
        <a class="item" href="https://shimo.im/docs/Tc8rrYtYKqv8h3Dy" target="blank">帮助中心</a>
        <router-link class="item" to="/protocol/user" target="blank">用户协议</router-link>
        <router-link class="item" to="/protocol/privacy" target="blank">隐私协议</router-link>
      </div>
      <div class="right">
        <div class="qrcode-wrap">
          <img src="https://res.oodcd.cn/1second/images/qrcode.jpg" class="qrcode">
          <p>扫码添加QQ群聊</p>
        </div>
        <div class="qrcode-wrap">
          <img src="https://res.oodcd.cn/1second/images/mp.jpg" class="qrcode">
          <p>一秒云联公众号</p>
        </div>
      </div>
    </div>
    <div class="copyright">
      <!-- <p>
        <span>Copyright © 2019-{{date}}</span>
        <span> | 上海冰慕网络科技有限公司 版权所有</span>
      </p> -->
      <p style="height: 24px; line-height: 24px;">
        <a href="https://beian.miit.gov.cn/"> 
          沪ICP备2021021945号-6
        </a>
        <!-- <span> | 沪B2-20211663</span>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006552">
           | <img src="https://res.oodcd.cn/1second/images/beian.png"/>
          <span>沪公网安备 31010602006552号</span>
        </a> -->
      </p>
      <!-- <p>
        <span>公司地址：上海市静安区南京西路818号1758室</span>
        <span>  邮政编码：200041</span>
        <span>  客服邮箱：kefu@1second.tech</span>
      </p> -->
    </div>
  </div>
</template>

<script>
import { getReleaseInfo } from '../api/common'
export default {
  data() { 
    return {
      config: {
        mac: '',
        windows: '',
        version: '',
        updatetime: '',
      },
      date: new Date().getFullYear()
    }
  },
  mounted () {
    getReleaseInfo().then(res => this.config = res)
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/_var.scss';

.home-page {
  .top {
    height: 540px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/pc_bg.png');
  }
}
.header {
  display: flex;
  width: $theme-width;
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 15px 0;
  align-items: center;
  .logo {
    height: 30px;
  }
  .nav-item {
    font-weight: bold;
  }
}
.intro-wrap {
  width: $theme-width;
  margin: auto;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .right {
    width: 400px;
  }
  h1 {
    font-weight: bolder;
    font-size: 44px;
  }
  .intro-tip {
    max-width: 530px;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: justify;
    color: #666;
  }
  .tip {
    font-size: 12px;
    margin-top: 10px;
    color: #666;
  }
  .button-wrap {
    margin-top: 20px;
    margin-bottom: 20px;
    .btn {
      cursor: pointer;
      color: #fff;
      padding: 13px 20px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 10px;
      background-image: linear-gradient(220deg, #80ABFF 0%, #2A40FF 100%);
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.summary {
  width: $theme-width;
  max-width: 100%;
  margin: 0 auto;
    text-align: center;
    padding: 40px;
    padding-top: 60px;
    padding-bottom: 40px;
    background-image: url('../assets/dot.png');
    background-repeat: no-repeat;
    background-position: 100% 50px;
    background-size: 138px 64px;
    h1 {
      font-size: 32px;
      font-weight: bolder;
    }
    .summary-tip {
      max-width: 600px;
      margin: auto;
      margin-top: 10px;
      font-size: 16px;
      color: #666;
    }
  }

.feature-section {
  width: $theme-width - 140;
  max-width: 100%;
  margin: 0 auto;
  .section-tip {
    font-size: 14px;
    color: #666;
    margin: 20px auto 20px;
    width: 80%;
  }
  .feature-wrap {
    .feature-item {
      padding: 40px 0;
      max-width: 1040px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .feature-intro {
        max-width: 460px;
      }
      .icon {
        height: 220px;
      }
      h3 {
        font-size: 32px;
        font-weight: bolder;
      }
      .detail {
        margin-top: 15px;
        font-size: 16px;
        color: #999;
      }
    }
  }
}
.footer {
  padding: 20px 0;
  width: 1020px;
  max-width: 100%;
  margin: 0 auto;
  font-size: 12px;
  color: #999;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #eee;
  .nav {
    display: flex;
    flex-direction: column;
    .title {
      color: #000;
      font-weight: bold;
    }
    .em {
      font-weight: bold;
    }
    .item {
      margin-top: 6px;
      color: #666;
    }
  }
  .nav-item {
    margin-left: 10px;
  }
  .right {
    display: flex;
  }
  .qrcode-wrap {
    &:last-child {
      margin-left: 20px;
    }
    .qrcode {
      width: 100px;
      padding: 6px;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
}
.copyright {
  color: #999;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  p {
    margin-top: 5px;
  }
  a {
    color: #999;
  }
  img {
    height: 18px;
    margin-left: 2px;
    margin-right: 5px;
    vertical-align: -4px;
  }
}
@media (max-width: 700px) {
  .home-page {
    .top {
      .shot {
        height: 180px;
      }
    }
  }
  .header {
    padding: 10px;
    .logo {
      height: 20px;
    }
  }
  .feature-section {
    .feature-wrap  {
      flex-direction: column;
      align-items: center;
      .feature-item {
        width: 80%;
      }
    }
  }
  .footer {
    padding: 10px;
    flex-direction: column;
    .nav {
      margin-top: 10px;
      flex-direction: row;
      justify-content: center;
      .title {
        margin-right: 10px;
      }
      .item {
        margin-top: 0;
        margin-right: 10px
      }
    }
  }
  .right {
    justify-content: center;
    margin-top: 10px;
  }
}
</style>

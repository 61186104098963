import Vue from 'vue'
import {mapState, mapGetters} from 'vuex'
import CODE from './constants/errcode'

Vue.mixin({
  data() {
    return {
      CODE,
    }
  },
  computed: {
    ...mapState('pan', [
      'panMode',
      'fidBreads',
      'currentFid',
      'uploadList',
      'downloadList',
      'downloadQueue',
      'currentFiles',
      'deletedFiles',
      'saveFiles',
      'shareFiles',
      'choosedFiles',
      'currentDevice',
    ]),
    ...mapGetters('pan', [
      'uploadSuccessNum',
      'downloadSuccessNum',
      'groupUploadList',
      'sortedUploadList',
      'groupDownloadList',
      'sortedDownloadList',
      'sortedCurrentFiles',
      'sortedDeletedFiles',
    ]),
  },
  methods: {
    showTip() {
      return this.$message.success('功能尚未实现')
    },
  }
})

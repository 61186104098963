const moment = require('moment')
import { UPLOAD_STATUS, DOWNLOAD_STATUS } from '@/constants/common'

const state = {
  fidBreads: [],
  uploadList: [],
  downloadList: [],
  downloadQueue: [],
  currentFid: '',
  currentFiles: [],
  choosedFiles: [],
  deletedFiles: [],
  shareFiles: [],
  saveFiles: [],
  panMode: 'pan-overview',
}

const UPLOAD_STATUS_ORDER = {
  [UPLOAD_STATUS.UPLOADING]: 5,
  [UPLOAD_STATUS.CHECK]: 4,
  [UPLOAD_STATUS.WAIT]: 3,
  [UPLOAD_STATUS.PAUSE]: 2,
  [UPLOAD_STATUS.ERROR]: 1,
  [UPLOAD_STATUS.DONE]: 0,
}

const DOWNLOAD_STATUS_ORDER = {
  [DOWNLOAD_STATUS.ACTIVE]: 5,
  [DOWNLOAD_STATUS.PAUSED]: 4,
  [DOWNLOAD_STATUS.ERROR]: 3,
  [DOWNLOAD_STATUS.COMPLETE]: 2,
}

const getters = {
  sortedCurrentFiles ({ currentFiles }) {
    return currentFiles.sort((a, b) => {
      if (a.isdir == b.isdir) {
        return moment(a.server_mtime).isBefore(b.server_mtime) ? 1 : -1
      } else {
        return b.isdir - a.isdir
      }
    })
  },
  sortedDeletedFiles ({ deletedFiles }) {
    return deletedFiles.sort((a, b) => {
      if (a.isdir == b.isdir) {
        return moment(a.createtime).isBefore(b.createtime) ? 1 : -1
      } else {
        return b.isdir - a.isdir
      }
    })
  },
  sortedUploadList ({ uploadList }) {
    return uploadList.sort((a, b) => {
      if (a.queueCreateTime != b.queueCreateTime) {
        return b.queueCreateTime || 0 - a.queueCreateTime || 0 > 0 ? -1 : 1
      } else if (UPLOAD_STATUS_ORDER[b.status] != UPLOAD_STATUS_ORDER[a.status]) {
        return UPLOAD_STATUS_ORDER[b.status] - UPLOAD_STATUS_ORDER[a.status]
      } else {
        return b.createAt || 0 - a.createAt || 0 > 0 ? -1 : 1
      }
    })
  },
  groupUploadList (_, { sortedUploadList }) {
    let lastItem = null
    const mapDir = []
    for (const item of sortedUploadList) {
      if (lastItem && item.queueCreateTime && item.queueCreateTime == lastItem.queueCreateTime) {
        mapDir[mapDir.length - 1].push(item)
      } else {
        mapDir.push([item])
        lastItem = item
      }
    }
    return mapDir
  },
  sortedDownloadList ({ downloadList }) {
    return downloadList.sort((a, b) => {
      if (a.extra.queueCreateTime != b.extra.queueCreateTime) {
        return b.extra.queueCreateTime || 0 - a.extra.queueCreateTime || 0 > 0 ? -1 : 1
      } else if (a.Status != b.Status) {
        return DOWNLOAD_STATUS_ORDER[b.Status] - DOWNLOAD_STATUS_ORDER[a.Status]
      } else {
        return b.extra.createAt || 0 - a.extra.createAt || 0 > 0 ? -1 : 1
      }
    })
  },
  groupDownloadList (_, { sortedDownloadList }) {
    let lastItem = null
    const mapDir = []
    for (const item of sortedDownloadList) {
      if (lastItem && item.extra.queueCreateTime && item.extra.queueCreateTime == lastItem.extra.queueCreateTime) {
        mapDir[mapDir.length - 1].push(item)
      } else {
        mapDir.push([item])
        lastItem = item
      }
    }
    return mapDir
  },
  uploadSuccessNum ({ uploadList }) {
    return uploadList.filter((item) => {
      return item.status === UPLOAD_STATUS.DONE
    }).length
  },
  downloadSuccessNum ({ downloadList }) {
    return downloadList.filter((item) => {
      return item.Status === DOWNLOAD_STATUS.COMPLETE
    }).length
  },
}

const mutations = {
  ADD_CURRENT_FILES (state, data = []) {
    state.currentFiles.push(...data)
  },
  UPDATE_CURRENT_FILES (state, data = []) {
    state.currentFiles = data
  },
  UPDATE_DELETED_FILES (state, data) {
    state.deletedFiles = data
  },
  UPDATE_SHARE_FILES (state, data) {
    state.shareFiles = data.map(item => {
      item.fid = item.shareid
      return item
    })
  },
  UPDATE_SAVE_FILES (state, data) {
    state.saveFiles = data
  },
  UPDATE_CHOOSED_FILE (state, data) {
    state.choosedFiles = data
  },
  ADD_CHOOSED_FILE (state, item) {
    state.choosedFiles.push(item)
  },
  REMOVE_CHOOSED_FILE(state, item) {
    state.choosedFiles.remove(item)
  },
  UPDATE_CURRENT_FID (state, data) {
    state.currentFid = data
  },
  UPDATE_UPLOAD_LIST (state, data) {
    state.uploadList = data
  },
  UPDATE_DOWNLOAD_LIST (state, data) {
    state.downloadList = data
  },
  UPDATE_DOWNLOAD_QUEUE (state, data) {
    state.downloadQueue = data
  },
  UPDATE_FID_BREADS (state, data) {
    state.fidBreads = data
  },
  ADD_FID_BREADS (state, data) {
    state.fidBreads.push(data)
  },
  SPLICE_FID_BREADS (state, fid) {
    let index = 0,
      len = state.fidBreads.length
    for (; index < len; index++) {
      if (fid === state.fidBreads[index].fid) break
    }
    state.fidBreads.splice(index + 1, len)
  },
  UPDATE_PAN_MODE(state, data) { 
    state.panMode = data
  }
}

const actions = {
  updateCurrentFiles ({ commit }, data) {
    commit('UPDATE_CURRENT_FILES', data)
  },
  addCurrentFiles ({ commit }, data) {
    commit('ADD_CURRENT_FILES', data)
  },
  updateDeletedFiles ({ commit }, data) {
    commit('UPDATE_DELETED_FILES', data)
  },
  updateShareFiles ({ commit }, data) {
    commit('UPDATE_SHARE_FILES', data)
  },
  updateSaveFiles ({ commit }, data) {
    commit('UPDATE_SAVE_FILES', data)
  },
  updateChoosedFiles ({ commit }, data) {
    commit('UPDATE_CHOOSED_FILE', data)
  },
  addChoosedFiles ({ commit }, data) {
    commit('ADD_CHOOSED_FILE', data)
  },
  removeChoosedFiles({ commit }, data) {
    commit('REMOVE_CHOOSED_FILE', data)
  },
  updateCurrentFid ({ commit }, data) {
    commit('UPDATE_CURRENT_FID', data)
  },
  updateUploadList ({ commit }, data) {
    commit('UPDATE_UPLOAD_LIST', data)
  },
  updateDownloadList ({ commit }, data) {
    commit('UPDATE_DOWNLOAD_LIST', data)
  },
  updateDownloadQueue ({ commit }, data) {
    commit('UPDATE_DOWNLOAD_QUEUE', data)
  },
  updateFidBreads ({ commit }, data) {
    commit('UPDATE_FID_BREADS', data)
  },
  addFidBreads ({ commit }, data) {
    commit('ADD_FID_BREADS', data)
  },
  spliceFidBreads ({ commit }, fid) {
    commit('SPLICE_FID_BREADS', fid)
  },
  updatePanMode ({ commit }, data) {
    commit('UPDATE_PAN_MODE', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
import axios from 'axios'

export const request = async (payload) => {
  const { 
    method ='GET', 
    data, 
    url, 
    params = {}, 
    headers = {}, 
    withCredentials = true,
  } = payload
  try {
    const res = await axios({
      method,
      url,
      data,
      timeout: 20 * 1000,
      params: {
        t: new Date().getTime(),
        ...params
      },
      headers: {
        ...headers
      },
      withCredentials,
    })
    return res.data
  } catch (err) {
    console.log(`request error: ${err}`)
    return null
  }
}

import Vue from 'vue'
import ElementUI from 'element-ui'
import '../scss/element.scss'

Vue.use(ElementUI)

Vue.prototype.$message = function(msg) {
  ElementUI.Message({ ...msg, duration: 1000 })
}

Vue.prototype.$message.error = function (msg, duration = 1500) {
  return ElementUI.Message.error({
    message: msg,
    duration
  })
}

Vue.prototype.$message.success = function (msg, duration = 1500) {
  return ElementUI.Message.success({
    message: msg,
    duration
  })
}

Vue.prototype.$message.warning = function (msg, duration = 1500) {
  return ElementUI.Message.warning({
    message: msg,
    duration
  })
}

Vue.prototype.$loading = ElementUI.Loading.service
Vue.prototype.$msgbox = ElementUI.MessageBox
Vue.prototype.$alert = ElementUI.MessageBox.alert
Vue.prototype.$confirm = ElementUI.MessageBox.confirm
Vue.prototype.$prompt = ElementUI.MessageBox.prompt
Vue.prototype.$notify = ElementUI.Notification

global.$message = Vue.prototype.$message
global.$message.error = Vue.prototype.$message.error
global.$message.success = Vue.prototype.$message.success

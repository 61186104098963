import moment from 'moment'
import mime from 'mime-types'
import FILE_TYPES from './constants/filetype'

Array.prototype.remove = function (val) {
  let index = this.indexOf(val)
  if (index > -1) {
    this.splice(index, 1)
  }
}

const install = (Vue) => {
  Vue.filter('formatPhone', (value) => {
    if (!value) {
      return value
    }
    return value.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
  })

  Vue.filter('bytesToSize', (bytes) => {
    if (bytes < 0) { return '0KB' }
    const b = parseInt(bytes, 10)
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
    if (isNaN(b)) { return '0KB' }
    if (b === 0) { return '0KB' }
    const i = parseInt(Math.floor(Math.log(b) / Math.log(1024)), 10)
    if (i === 0) { return `${b}${sizes[i]}` }
    if (i > 1) {
      return `${(b / (1024 ** i)).toFixed(0)}${sizes[i]}`
    } else {
      return `${(b / (1024 ** i)).toFixed(1)}${sizes[i]}`
    }
  })

  Vue.filter('formatSpeed', (bytes) => {
    if (bytes < 0) { return '0kb' }
    const b = parseInt(bytes, 10)
    const sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb']
    if (isNaN(b)) { return '0Kb' }
    if (b === 0) { return '0Kb' }
    const i = parseInt(Math.floor(Math.log(b) / Math.log(1024)), 10)
    if (i === 0) { return `${b}${sizes[i]}` }
    if (i > 1) {
      return `${(b / (1024 ** i)).toFixed(2)}${sizes[i]}`
    } else {
      return `${(b / (1024 ** i)).toFixed(0)}${sizes[i]}`
    }
  })

  Vue.filter('formatDate', (value, format) => {
    if (!value) return '--'
    if (format) {
      return moment(value).format(format)
    }
    return moment(value).format('YYYY/MM/DD')
  })

  Vue.filter('fromNow', (value) => {
    return moment(value).fromNow()
  })

  Vue.filter('formatTimestamp', (value, format) => {
    if (!value) return '--'
    if (format) {
      return moment(parseInt(value)).format(format)
    }
    return moment(parseInt(value)).format('YYYY/MM/DD')
  })

  Vue.filter('formatFileType', (filename, isFolder) => {
    if (isFolder) {
      return FILE_TYPES.FILE_TYPE_FOLDER
    }

    if (filename.endsWith('gif')) {
      return FILE_TYPES.FILE_TYPE_GIF
    }
    
    if (filename.endsWith('mkv') || filename.endsWith('mkv1')) {
      return FILE_TYPES.FILE_TYPE_VIDEO
    }

    if (filename.endsWith('sketch')) {
      return FILE_TYPES.FILE_TYPE_SKETCH
    }

    if (filename.endsWith('azw3')) {
      return FILE_TYPES.FILE_TYPE_AZW3
    }

    if (filename.endsWith('key')) {
      return FILE_TYPES.FILE_TYPE_KEYNOTE
    }

    const mimeType = mime.lookup(filename)

    if (!mimeType) {
      return FILE_TYPES.FILE_TYPE_UNKNOWN
    }

    if (mimeType.match('application/x-mobipocket-ebook')) {
      return FILE_TYPES.FILE_TYPE_MOBI
    }

    if (mimeType.match('application/vnd.apple.numbers')) {
      return FILE_TYPES.FILE_TYPE_NUMBERS
    }

    if (mimeType.match('application/vnd.apple.pages')) {
      return FILE_TYPES.FILE_TYPE_PAGES
    }

    if (mimeType.match('epub')) {
      return FILE_TYPES.FILE_TYPE_EPUB
    }

    if (mimeType.match('application/postscript')) {
      return FILE_TYPES.FILE_TYPE_AI
    }

    if (mimeType.match('photoshop')) {
      return FILE_TYPES.FILE_TYPE_PSD
    }

    if (mimeType.match('application/x-msdos-program')) {
      return FILE_TYPES.FILE_TYPE_EXE
    }

    if (mimeType.match('application/vnd.android.package-archive')) {
      return FILE_TYPES.FILE_TYPE_APK
    }
    
    if (
      mimeType.match('ms-powerpoint') ||
      mimeType.match('officedocument.presentationml.presentation')
    ) {
      return FILE_TYPES.FILE_TYPE_PPT
    }

    if (
      mimeType.match('ms-excel') ||
      mimeType.match('officedocument.spreadsheetml.sheet')
    ) {
      return FILE_TYPES.FILE_TYPE_XLS
    }

    if (
      mimeType.match('msword') ||
      mimeType.match('officedocument.wordprocessingml.document')
    ) {
      return FILE_TYPES.FILE_TYPE_DOC
    }

    if (mimeType.match('compressed') || mimeType.match('zip') || mimeType.match('gzip')) {
      return FILE_TYPES.FILE_TYPE_ZIP
    }

    if (mimeType.match('application/pdf')) {
      return FILE_TYPES.FILE_TYPE_PDF
    }

    if (mimeType.match('application/x-bittorrent')) {
      return FILE_TYPES.FILE_TYPE_BT
    }

    if (mimeType.match('audio/')) {
      return FILE_TYPES.FILE_TYPE_AUDIO
    }

    if (mimeType.match('video/')) {
      return FILE_TYPES.FILE_TYPE_VIDEO
    }

    if (mimeType.match('image/')) {
      return FILE_TYPES.FILE_TYPE_IMG
    }

    return FILE_TYPES.FILE_TYPE_UNKNOWN
  })
}

export default { install }
export default {
  success:                   0,
  error:                     -1,
  invalid_token:             1001,     // token无效
  invalid_params:            1002,     // 参数错误
  invalid_captcha:           1003,     // 图片验证码错误
  invalid_email_code:        1004,     // 邮箱验证码错误
  invalid_phone_code:        1005,     // 手机验证码错误
  invalid_email:             1006,     // 邮箱格式错误 / 邮箱错误
  invalid_phone:             1007,     // 邮箱格式错误 / 邮箱错误
  invalid_nickname:          1008,     // 昵称格式错误 / 昵称错误
  invalid_password:          1009,     // 密码格式错误 / 密码错误

  qrcode_not_scan:           2001,     // 没有扫码
  wx_code_error:             2002,     // 微信登录 code 错误              
  user_not_exist:            2003,     // 用户不存在
  email_exist:               2004,     // 邮箱已经存在
  wechat_not_bind:           2005,     // 微信尚未绑定具体用户
  qrcode_login_error:        2006,     // 扫码登录错误

  jwt_generate_error:        510004,   // jwt生成错误

  create_order_error:        510011,   // 生成支付订单错误
  create_pay_url_error:      510012,   // 生成支付连接错误
  order_not_exist:           510013,   // 订单不存在
  order_not_paid:            510014,   // 订单未支付
  order_expired:             510015,   // 订单已过期
  pay_plt_error:             510016,   // 支付平台错误

  not_enough_traffic:        511001,   // 没有足够的流量
  consume_traffic_error:     511002,   // 消耗流量出错
  redemption_error:          511003,   // 兑换出错

  not_enough_storage:        520000,   // 没有足够的网盘空间
  hash_conflict:             530001,  // 头部hash冲突
  filename_too_long:         530002,  // 文件名称过长
  invalid_path_or_fid:       530003,  // parentpath 和 parentfid 不匹配
  filename_invalid:          530004,  // 文件名称含有不合法字符
  get_upload_info_err:       530005,  // 获取上传信息错误
  file_not_complete:         530006,  // 文件校验不完整
  file_not_exist:            530007,  // 文件不存在
  move_sub_folder_err:       530008,  // 不能移动文件到子文件夹

  too_many_devices:          540001,  //当前登录设备数超出最大限制数
  
  share_files_empty:         550001,  // 分享文件为空
  share_history_empty:       550002,  // 没有改分享记录
  share_id_error:            550003,  // 分享链接错误
  share_pwd_error:           550004,  // 提取码错误
}